<template>
  <div class="lenovo">
    <header>
      <img src="~img/02.png">
      <img src="~img/03.png" class="zc">
    </header>
    <main>
      <van-uploader :after-read="afterRead">
        <van-cell-group :border='false'>
          <van-cell title="上传头像">
            <template #right-icon>
              <img :src="headImg" class="header_img">
              <van-icon name="arrow" class="arrow-icon" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-uploader>
      <ul>
        <li>
          <span class="biaoshi">*</span>
          <div class="check_box">
            <van-checkbox v-model="checked">
              联想员工
              <template #icon="props">
                <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon" />
              </template>
            </van-checkbox>
          </div>
        </li>
        <li>
          <span class="biaoshi">*</span>
          <input type="text" placeholder="请填写昵称" v-model="lenovoUser.firstname">
        </li>
        <li>
          <span class="biaoshi">*</span>
          <input type="text" :placeholder="placeholderText" readonly="readonly" v-model="lenovoUser.email">
        </li>
        <li>
          <span class="biaoshi">*</span>
          <input type="number" placeholder="输入图形验证码" v-model="codekey">
          <img :src="codeImg" class="code-img" @click="getCaptch">
        </li>
        <li>
          <span class="biaoshi">*</span>
          <input type="number" placeholder="输入邮箱验证码" v-model="code">
          <button @click="getCode">{{btnText}}</button>
        </li>
        <li>
          <input type="number" placeholder="请填写手机号" v-model="phone">
        </li>
      </ul>
      <button class="register" @click="register">注册账号</button>
    </main>
    <van-overlay :show="showOverlay">
      <van-loading color="#1989fa" />
    </van-overlay>
  </div>
</template>


<script>
export default {
  name: "lenovoRegister",
  data() {
    return {
      lenovoUser: {},
      checked: true,
      headImg: require("../../assets/img/04.png"),
      activeIcon: require("../../assets/img/06.png"),
      inactiveIcon: require("../../assets/img/07.png"),
      showOverlay: false,
      interval: null,
      avatar: "",
      time: 60,
      btnText: "获取验证码",
      code: "",
      phone: "",
      codekey: "",
      codeImg: "",
      key: "",
      placeholderText: "请输入邮箱",
    };
  },
  created() {
    this.getCaptch();
    this.lenovoUser = JSON.parse(localStorage.getItem("lenovouser"));
    console.log(this.lenovoUser);
    let url =
      "http://files.cochat.lenovo.com/download/dbb26a06-4604-3d2b-bb2c-6293989e63a7/";
    this.headImg = url + this.lenovoUser.imageUri;
    this.avatar = url + this.lenovoUser.imageUri;
  },
  methods: {
    afterRead(file) {
      console.log(file);
      this.showOverlay = true;
      let formData = new FormData();
      formData.append("file", file.file);
      this.updata(formData);
    },
    async updata(file) {
      const res = await this.axios.post("upload", file, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log(res);
      if (res.code === 200) {
        this.showOverlay = false;
        this.headImg = res.data.url;
        this.avatar = res.data.avatar;
      }
    },
    async getCode() {
      if (this.time < 60) return;
      if (this.codekey.length <= 0) {
        this.$toast("请输入图形验证码");
        return;
      }
      const res = await this.axios.post("send_email", {
        push: this.lenovoUser.email,
        captch: this.codekey,
        key: this.key,
      });
      console.log(res);
      if (res.code === 200) {
        this.interval = setInterval(() => {
          if (this.time > 0) {
            this.time--;
            this.btnText = this.time + "重新发送";
          } else {
            clearInterval(this.interval);
            this.btnText = "获取验证码";
            this.time = 60;
          }
        }, 1000);
      } else {
        this.$toast(res.message);
      }
    },
    async register() {
      const res = await this.axios.post("auth/lenovo/register", {
        name: this.lenovoUser.firstname,
        itcode: this.lenovoUser.itcode,
        email: this.lenovoUser.email,
        phone: this.phone,
        code: this.code,
        avatar: this.avatar,
      });
      if (res.code === 200) {
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.user));
        this.$dialog
          .alert({
            message: "注册成功",
          })
          .then(() => {
            // on close
            this.$router.push({ path: "/" });
          });
      } else {
        this.$toast(res.message);
      }
    },
    async getCaptch() {
      const res = await this.axios.get("auth/captch");
      console.log(res);
      if (res.code === 200) {
        this.codeImg = res.data.captch.img;
        this.key = res.data.captch.key;
      }
    },
  },
};
</script>


<style lang="less" scoped>
.lenovo {
  height: 100%;
  position: relative;
  li {
    font-size: 0;
  }
  header {
    position: relative;
    .zc {
      width: 280px;
      height: 117px;
      position: absolute;
      left: 70px;
      top: 96px;
    }
  }
  main {
    width: 92%;
    height: 73%;
    border-radius: 18px;
    background: #fff;
    position: absolute;
    top: 323px;
    left: 50%;
    transform: translate(-50%);
    padding-top: 20px;
    font-family: SourceHanSansCN-Normal, SourceHanSansCN;
    font-weight: 400;
    .header_img {
      width: 65px;
    }
    .arrow-icon {
      line-height: 65px;
      font-size: 24px;
      color: #9f9f9f;
      margin-left: 18px;
    }
    .van-uploader {
      width: 100%;
      /deep/.van-uploader__input-wrapper {
        width: 100%;
      }
    }
    .van-cell-group {
      border-bottom: 1px solid #ebebeb;
      width: 89%;
      margin: auto;
      .van-cell {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 15px;
      }
    }
    .van-cell__title {
      color: #a4a4a4;
      font-size: 14px;
    }
    ul {
      li {
        border-bottom: 1px solid #ebebeb;
        height: 88px;
        background: #fff;
        width: 88%;
        margin: auto;
        position: relative;
        padding: 0;
        button {
          width: 174px;
          height: 62px;
          background: transparent;
          border: 1px solid #b8262e;
          border-radius: 62px;
          font-size: 28px;
          color: #b8262e;
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translate(0, -50%);
        }
        .code-img {
          width: 200px;
          position: absolute;
          right: 0px;
          top: 50%;
          transform: translate(0, -50%);
        }
        .biaoshi {
          position: absolute;
          top: 50%;
          left: -25px;
          transform: translate(0, -40%);
          font-size: 24px;
          color: #dd1717;
        }
        .check_box {
          display: flex;
          height: 100%;
        }
        .van-checkbox {
          height: 100%;
          margin-right: 20%;
          .img-icon {
            height: 12.5px;
            width: 12.5px;
          }
          /deep/.van-checkbox__icon {
            height: auto;
          }
          /deep/.van-checkbox__label {
            font-size: 14px;
            color: #a4a4a4;
          }
        }

        input {
          width: 100%;
          font-size: 28px;
          height: 100%;
          color: #a4a4a4;
        }
        ::-webkit-input-placeholder {
          /* WebKit browsers，webkit内核浏览器 */
          color: #a4a4a4;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        }
        :-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #a4a4a4;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        }
        ::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #a4a4a4;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        }
        :-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #a4a4a4;
          font-family: SourceHanSansCN-Normal, SourceHanSansCN;
        }
      }
    }
  }
  .van-loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .register {
    width: 609px;
    height: 81px;
    background: url("../../assets/img/05.png") no-repeat;
    background-size: contain;
    font-size: 32px;
    color: #fff;
    display: block;
    margin: auto;
    margin-top: 150px;
    // position: absolute;
    // left: 50%;
    // bottom: 28px;
    // transform: translate(-50%);
  }
}
</style>
<template>
  <div class="register">
    <wx-register v-if="iswx" />
    <lenovo-register v-else />
  </div>
</template>


<script>
import lenovoRegister from "./leRegister.vue";
import wxRegister from "./wxRegister.vue";
export default {
  components: {
    lenovoRegister,
    wxRegister,
  },
  data() {
    return {
      iswx: false,
    };
  },
  created() {
    this.iswx = this.common.is_weixin();
  },
};
</script>


<style lang="less" scoped>
.register {
  height: 100vh;
}
</style>